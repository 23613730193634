import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import Instructions from "src/components/instructions"

const InstructionsData = import("./data/data.json");

export default function prdbest() {

  return(
    <HomepageLayout>
    <Helmet>
    <title>Get Curated Content and Apps - Carbonate</title>
    </Helmet>
    <section>
      <Instructions data={InstructionsData}></Instructions>
      </section>
    </HomepageLayout>

  )
  }
